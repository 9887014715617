import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setImage } from "../../features/imageSlice";
import "./Image.css";
import { addImage, removeImage } from "../../features/selectedImagesSlice";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import ImageView from "../ImageView/ImageView";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";

function Image({ image, id, navigationKey }) {
  const dispatch = useDispatch();
  const selectedImages = useSelector((state) => state.selectedImages.images);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previousURL, setPreviousURL] = useState("");
  const config = JSON.parse(sessionStorage.getItem("config"));

  const showModal = () => {
    var body = document.getElementsByTagName("html")[0];
    body.style.overflow = "hidden";
    setPreviousURL(window.location.href); // Store the previous URL
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    var body = document.getElementsByTagName("html")[0];
    body.style.overflow = "auto";
    setIsModalOpen(false);
    window.history.pushState(null, "", previousURL); // Restore the previous URL
  };

  const openImage = () => {
    showModal();
    dispatch(setImage(image));
    const imageURL = `/gallery/${id}`;
    window.history.pushState({ url: imageURL }, "", imageURL); // Update the URL with the image URL
  };

  useEffect(() => {
    const handlePopstate = (event) => {
      if (event.state && event.state.url) {
        showModal();
      } else {
        handleCancel();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const selectImage = (event) => {
    console.log("test", event.target.checked, event.target.id);
  };

  const handleCheckboxChange = (event) => {
    const id = event.target.id;
    if (event.target.checked) {
      dispatch(addImage(id));
    } else {
      dispatch(removeImage(id));
    }
  };

  useEffect(() => {
    // Clear all checkboxes if selectedImages is empty
    if (selectedImages.length === 0) {
      const checkboxes = document.querySelectorAll(".checkbox-round");
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }, [selectedImages]);

  const [imageFound, setImageFound] = useState(true); // Initialize with true

  const handleImageLoad = () => {
    setImageFound(true);
  };

  const handleImageError = () => {
    setImageFound(false);
  };

  const handleFavorite = () => {
    console.log("Favorited image", image);
  };

  return (
    <div className="grid-image-container">
      <div
        className={
          selectedImages.length === 0
            ? "chose-icon-container"
            : "chose-icon-container-active"
        }
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <input
          className="checkbox-round"
          type="checkbox"
          id={image["url"]}
          onChange={handleCheckboxChange}
        />
      </div>

      {/* <div className={`favorite`} onClick={handleFavorite}>
        {image.favorite ? <StarRoundedIcon /> : <StarOutlineRoundedIcon />}
      </div> */}

      <div className="image">
        {config.shouldDisplaySidebarTabs ? (
          <div className="favorite-container">
            <div className={`favorite`} onClick={handleFavorite}>
              {image.favorite ? (
                <StarRoundedIcon />
              ) : (
                <StarOutlineRoundedIcon />
              )}
            </div>
          </div>
        ) : null}

        {image["type"] === "image" ? (
          <div className="image">
            {imageFound ? (
              <img
                src={image["thumbnail"]}
                loading="lazy"
                onLoad={handleImageLoad}
                onError={handleImageError}
                onClick={selectedImages.length === 0 ? openImage : null}
              />
            ) : (
              <img
                src={image["url"]}
                onClick={selectedImages.length === 0 ? openImage : null}
              />
            )}
          </div>
        ) : (
          <div className="play-video-container">
            {/* <img
              src={image["thumbnail"]}
              className="play-video"
              loading="lazy"
              onClick={selectedImages.length === 0 ? openImage : null}
            /> */}
            {imageFound ? (
              <img
                src={image["thumbnail"]}
                className="play-video"
                loading="lazy"
                onLoad={handleImageLoad}
                onError={handleImageError}
                onClick={selectedImages.length === 0 ? openImage : null}
              />
            ) : (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/640px-A_black_image.jpg"
                className="play-video"
                onClick={selectedImages.length === 0 ? openImage : null}
              />
            )}
          </div>
        )}

        {/* Modal */}
        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[]}
          centered
          width={"100%"}
          destroyOnClose={true}
        >
          {/* <p 
            style={{
              fontSize: "20px",
              color: "white",
            }}
          >
            Key: <strong>{navigationKey}</strong>
          </p> */}
          <ImageView
            image={image}
            currentNavigationKey={navigationKey}
            refferer={"gallery"}
          />
        </Modal>
        {/* {image["id"]} */}
      </div>
    </div>
  );
}

export default Image;
