import React from "react";
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Footer.css";
import config from "../../config/config.js";
import { Tooltip } from "antd";
import { IconButton } from "@mui/material";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import { Avatar } from "antd";
import { Button, Popover } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { setUser } from "../../features/userSlice";
import { setRoles, setJWT } from "../../features/userSlice";

import jwt_decode from "jwt-decode";

function Footer({ isInsideGallery = false }) {
  const user = useSelector((state) => state.user);
  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(localStorage.getItem("translations"));
  const config = JSON.parse(localStorage.getItem("config"));
  const direction = useSelector((state) => state.language.direction);

  const [arrow, setArrow] = useState("Show");

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const [openAccountPopup, setOpenAccountPopup] = useState(false);

  const hide = () => {
    setOpenAccountPopup(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpenAccountPopup(newOpen);
  };

  const dispatch = useDispatch();

  function handleCallbackResponse(response) {
    dispatch(setJWT(response.credential));

    var userObject = jwt_decode(response.credential);

    dispatch(setUser(userObject));

    // Get roles
    const fetchData = async () => {
      try {
        const userRolesResponse = await fetch(
          process.env.REACT_APP_BACKEND_API_URL + "/api/postUserRole",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              jwt: response.credential,
            }),
          }
        );

        if (userRolesResponse.ok) {
          // Request succeeded, handle the response here
          const data = await userRolesResponse.json();
          console.log("Google Response Data --->", data);
          dispatch(setRoles(data)).then(window.location.reload());
        } else {
          // Request failed, handle the error
          console.error("Request failed:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }

  const signIn = () => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_SIGN_IN_API,
        callback: handleCallbackResponse,
      });

      window.google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
          window.google.accounts.id.prompt();
        }
      });
    }
  };

  const signOut = () => {
    dispatch(setUser({}));
    dispatch(setRoles([]));
    dispatch(setJWT(""));

    localStorage.removeItem("user");

    window.location.href = "/";
  };

  // Get footer links
  const [footerLinks, setFooterLinks] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_API_URL + "/api/getNavigationLinks",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              placement: "footer",
            }),
          }
        );

        if (response.ok) {
          // Request succeeded, handle the response here
          const data = await response.json();
          setFooterLinks(data);
        } else {
          // Request failed, handle the error
          console.error("Request failed:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div
      className="footer"
      style={{
        background: `${config.white}`,
        border: `1px solid whitesmoke`,
      }}
    >
      <div className="footer-buttons">
        {config.shouldDisplayFooterNavs === true && (
          <div className="footer-buttons">
            {footerLinks.map((link) => (
              <a
                href={`/${link.link}`}
                style={{
                  textDecoration: "none",
                }}
                key={link.id}
              >
                <p
                  style={{
                    // textColor
                    color: `${config.textColor}`,
                  }}
                >
                  {
                    translations.find(
                      (translation) =>
                        translation.id === `${link.translationsId}`
                    )?.[language]
                  }
                </p>
              </a>
            ))}

            {user.user.picture ? (
              <Tooltip
                placement="left"
                title={
                  translations.find(
                    (translation) => translation.id === "footer.signout"
                  )?.[language]
                }
                arrow={mergedArrow}
              >
                <Popover
                  content={
                    <div>
                      <a className="popover-button" onClick={signOut}>
                        {
                          translations.find(
                            (translation) => translation.id === "footer.signout"
                          )?.[language]
                        }
                      </a>
                    </div>
                  }
                  title={
                    <div>
                      {/* {user.user.name.split(".")[0].charAt(0).toUpperCase() +
                        user.user.name.split(".")[0].slice(1) +
                        " " +
                        user.user.name.split(".")[1].charAt(0).toUpperCase() +
                        user.user.name.split(".")[1].slice(1)} */}
                      {/* role */}
                      <br />
                      {/* {user.roles[0]} */}
                      {
                        translations.find(
                          (translation) =>
                            translation.id === "roles." + `${user.roles[0]}`
                        )?.[language]
                      }
                    </div>
                  }
                  // title={
                  //   user.user.name.split(".")[0].charAt(0).toUpperCase() + user.user.name.split(".")[0].slice(1)
                  // }
                  trigger="click"
                  open={openAccountPopup}
                  onOpenChange={handleOpenChange}
                  style={{
                    direction: `${direction}`,
                  }}
                >
                  <div
                    className="sign-out-popup-button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                    // onClick={signOut}
                  >
                    <Avatar
                      src={user.user.picture}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        color: `${config.textColor}`,
                      }}
                    ></p>
                    {/* <LogoutOutlined /> */}
                    {/* <IconButton
                  style={{
                    color: `${config.textColor}`,
                  }}
                >
                </IconButton> */}
                  </div>
                </Popover>
              </Tooltip>
            ) : (
              !isInsideGallery && (
                <p
                  style={{
                    color: `${config.textColor}`,
                    cursor: "pointer",
                  }}
                  onClick={signIn}
                >
                  {
                    translations.find(
                      (translation) => translation.id === "footer.login"
                    )?.[language]
                  }
                </p>
              )
            )}
          </div>
        )}
      </div>

      {/* <div className="footer-links">
        <a href="/signin">
          <UserOutlined />
          {
            translations.find(
              (translation) => translation.id === "footer.login"
            )?.[language]
          }
        </a>
      </div> */}
      <p
        style={{
          color: `${config.textColor}`,
        }}
      >
        {
          translations.find(
            (translation) => translation.id === "footer.copyrights"
          )?.[language]
        }
      </p>

      {/* Version */}
      <p
        style={{
          // color: `${config.textColor}`,
          color: `#d3d3d3`,
          position: "fixed",
          bottom: "0",
          left: "0",
          margin: "10px",
          fontSize: "14px",
        }}
      >
        {config.version}
      </p>
    </div>
  );
}

export default Footer;
