import { createSlice } from "@reduxjs/toolkit";

export const globalsSlice = createSlice({
  name: "globals",
  initialState: {
    globals: {
      reset: false,
      navigationKey: 0,
    },
  },
  reducers: {
    setGlobals: (state, action) => {
      state.globals = action.payload;
    },
    setGlobalNavigationKey: (state, action) => {
      state.globals.navigationKey = action.payload;
    },
  },
});

export const { setGlobals, setGlobalNavigationKey } = globalsSlice.actions;

export default globalsSlice.reducer;
