import React from "react";

const MetaDecorator = ({ title, description, image }) => {
  const defaultTitle = "לע״מ";
  const defaultDescription = "לשכת העיתונות הממשלתית";
  const defaultImage =
    "https://storage.googleapis.com/press-office-storage-bucket-public-website-assets/logo.png"; // Set a default image URL if not provided

  const metaTags = [
    { property: "og:title", content: title || defaultTitle },
    { property: "og:description", content: description || defaultDescription },
    { property: "og:image", content: image || defaultImage },
    {
      property: "og:url",
      content: `${window.location.pathname + window.location.search}`,
    },
  ];

  return (
    <>
      {metaTags.map((tag, index) => (
        <meta key={index} property={tag.property} content={tag.content} />
      ))}
    </>
  );
};

export default MetaDecorator;
