import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Hero.css";

import { AutoComplete } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import config from "../../config/config";

import Searchbar from "../Searchbar/Searchbar";

function Hero() {
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const searchTerm = queryParams.get("search");

  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(sessionStorage.getItem("translations"));
  const config = JSON.parse(sessionStorage.getItem("config"));
  const direction = useSelector((state) => state.language.direction);

  let searching = false;

  async function getSearchCompletion(searchedText) {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_API_URL + `/api/getSearchTerms`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchedText: searchedText,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const newOptions = data.map((word) => ({
          label: word,
          value: word,
        }));

        // Check if searchedText is already in the options
        const searchedTextOption = newOptions.find(
          (option) => option.value === searchedText
        );

        // If not found, add it to the options
        if (!searchedTextOption) {
          newOptions.push({
            label: searchedText,
            value: searchedText,
          });
        }

        setOptions(newOptions);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setOptions([]);
      console.error(`Error fetching data: ${error}`);
    }
  }

  const updateQueryParams = () => {
    // Get the updated query string
    const updatedQueryString = queryParams.toString();

    // Replace the current URL without triggering a full page reload
    window.history.replaceState(null, "", `gallery?${updatedQueryString}`);

    window.location.reload();
  };

  const onSelect = (data) => {
    searching = true;

    setValue(data);

    postSearchTerm(data);

    queryParams.set("search", data);

    updateQueryParams();

    setTimeout(() => {
      searching = false;
    }, 1000);
  };

  const onKeyDown = (event) => {
    if (searching) {
      return;
    } else {
      // check if autocomplete is open
      if (event.keyCode === 13 && value.length <= 0) {
        onSelect("");
      } else if (event.keyCode === 13 && options.length >= 0) {
        onSelect(value);
      }
    }
  };

  const postSearchTermEndpoint =
    process.env.REACT_APP_BACKEND_API_URL + "/api/postSearchTerm";

  const postSearchTerm = (searchTerm) => {
    if (searchTerm.length > 0) {
      try {
        fetch(postSearchTermEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            search: searchTerm,
          }),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const onChange = (data) => {
    setValue(data);
  };

  let heroBackgroundImage = config.heroBackgroundImage;
  // let heroBackgroundImage = "";

  if (!heroBackgroundImage || heroBackgroundImage === "") {
    heroBackgroundImage =
      "https://storage.googleapis.com/kal-media-placeholders/placeholder-image.webp";
    // heroBackgroundImage = "../../config/placeholder-image.webp";
  }

  return (
    <>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${heroBackgroundImage})`,
          color: `${config.white}`,
        }}
      >
        <h1 className="title-hero">
          {
            translations.find(
              (translation) => translation.id === "home.title"
            )?.[language]
          }
        </h1>
        <p
          className="description-hero"
          style={{
            color: `${config.white}`,
          }}
        >
          {
            translations.find(
              (translation) => translation.id === "home.description"
            )?.[language]
          }
        </p>
        {/* <div
          className="search-bar-hero"
          style={{
            backgroundColor: `${config.white}`,
          }}
        >
          <AutoComplete
            defaultValue={searchTerm}
            options={options}
            style={{ width: "100%", direction: direction }}
            onSelect={onSelect}
            onSearch={(value) => {
              if (value.trim() === "") {
                setOptions([]);
              } else {
                getSearchCompletion(value.trim());
              }
            }}
            onChange={onChange}
            placeholder={
              translations.find(
                (translation) => translation.id === "header.search_placeholder"
              )?.[language]
            }
            onKeyDown={onKeyDown}
          />
          <div
            className="search-button"
            onClick={() => {
              if (value.trim() === "") {
                onSelect("");
              } else {
                onSelect(value.trim());
              }
            }}
            style={{
              backgroundColor: `${config.heroSearchBarButtonBackgroundColor}`,
              color: `${config.white}`,
            }}
          >
            <SearchIcon />
          </div>
        </div> */}

        {/* Searchbar START */}
        <Searchbar />
        {/* Searchbar END */}

        {/* continue to gallery link */}
        {config.shouldDisplayEntranceToGallery && (
          <div
            className="continue-to-gallery"
            onClick={() => {}}
            style={{
              color: `${config.white}`,
              zIndex: 999,
              fontSize: "18px",
            }}
          >
            <a href="/gallery">
              {
                translations.find(
                  (translation) => translation.id === "home.go_to_gallery"
                )?.[language]
              }
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default Hero;
