import React, { useState } from "react";
import { useSelector } from "react-redux";

import SidebarOption from "./SidebarOption/SidebarOption";
import CollectionsIcon from "@mui/icons-material/Collections";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CategoryIcon from "@mui/icons-material/Category";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import ImageIcon from "@mui/icons-material/Image";

import { useNavigate } from "react-router-dom";

import "./Sidebar2.css";

import Filters from "../Filters/Filters";

function Sidebar() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [collapseFilters, setCollapseFilters] = useState(false);

  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(sessionStorage.getItem("translations"));

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const toggleFilters = () => {
    setCollapseFilters(!collapseFilters);
  };

  const config = JSON.parse(sessionStorage.getItem("config"));

  return (
    <div>
      <div className={`sidebar-toggle`} onClick={toggleSidebar}>
        {showSidebar ? <CloseIcon /> : <MenuIcon />}
      </div>

      <div
        className={`sidebar ${showSidebar ? `show` : ``}`}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {config.shouldDisplaySidebarTabs && (
          <div className="options-container">
            <SidebarOption
              Icon={ImageIcon}
              title={
                translations.find(
                  (translation) => translation.id === "sidebar.gallery"
                )?.[language]
              }
              selected={true}
              onClick={() => navigate("/categories")}
              className="sidebar-option"
              link={"/gallery"}
            />

            <SidebarOption
              Icon={StarRoundedIcon}
              title={
                translations.find(
                  (translation) => translation.id === "sidebar.favorites"
                )?.[language]
              }
              className="sidebar-option"
              link={"/albums/favorites"}
            />

            <SidebarOption
              Icon={CollectionsIcon}
              title={
                translations.find(
                  (translation) => translation.id === "sidebar.albums"
                )?.[language]
              }
              onClick={() => navigate("/categories")}
              className="sidebar-option"
              link={"/albums"}
            />
          </div>
        )}
        <Filters />
      </div>
    </div>
  );
}

export default Sidebar;
