import React from "react";

import "./SidebarOption.css";

function SidebarOption({ Icon, title, selected, link, onClick }) {
  onClick = () => {
    if (link) {
      window.location.href = link;
    } else {
      console.log("No link provided");
    }
  };

  return (
    <div
      className={`sidebarOption ${selected && "sidebarOption--active"}`}
      onClick={onClick}
    >
      <Icon />
      <h3>{title}</h3>
    </div>
  );
}

export default SidebarOption;
