import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Album.css";
import { useSelector, useDispatch } from "react-redux";

function Album({ id, album_name }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [albumImages, setAlbumImages] = useState([]);

  useEffect(() => {
    fetchDataAlbumImages();
  }, []);

  const fetchDataAlbumImages = () => {
    const getAlbumImagesApiEndpoint =
      process.env.REACT_APP_BACKEND_API_URL + "/api/getAlbumImages";
    try {
      fetch(`${getAlbumImagesApiEndpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          album_name: album_name,
          user_id: "snirego@kaleidoo.ai",
        }),
      })
        .then((response) => response.json())
        .then((response_data) => {
          setAlbumImages(response_data.slice(-4));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAlbumClick = () => {
    navigate(`/albums/${album_name}`);
  };

  return (
    <div
      className="album"
      onClick={handleAlbumClick}
      style={{
        order: id,
        backgroundImage: `${
          albumImages.length > 0 && albumImages.length < 4
            ? `url(${albumImages[0].image})`
            : ""
        }`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="albumOverlay">
        <div className="albumTitle">
          <h2>{album_name}</h2>
        </div>
      </div>
      {albumImages.length >= 4 && (
        <div className="imageCollage">
          {albumImages.map((image, index) => (
            <img key={index} src={image.image} alt={`Image ${index}`} />
          ))}
        </div>
      )}
    </div>
  );
}

export default Album;
