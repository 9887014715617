import { createSlice } from "@reduxjs/toolkit";

export const dataSlice = createSlice({
  name: "data",
  initialState: {
    data: {
      sort: "date",
      order: "desc",
      search: "",
      page: 1,
      filters: {
        any: [],
      },
      jwt: "",
    },
  },
  reducers: {
    setData: (state, action) => {
      if (
        action.payload === {} ||
        action.payload === null ||
        action.payload === ""
      ) {
        return;
      }
      state.data = action.payload;
    },
    addDataFilter: (state, action) => {
      if (
        action.payload === {} ||
        action.payload === null ||
        action.payload === ""
      ) {
        return;
      }
      const newFilter = action.payload;
      // check if filter already exists, if so, replace it
      const filterIndex = state.data.filters.any.findIndex(
        (filter) => Object.keys(filter)[0] === Object.keys(newFilter)[0]
      );
      if (filterIndex !== -1) {
        state.data.filters.any[filterIndex] = newFilter;
      } else {
        state.data.filters.any.push(newFilter);
      }
    },
    setDate: (state, action) => {
      if (
        action.payload === {} ||
        action.payload === null ||
        action.payload === ""
      ) {
        return;
      }
      // check if filter already exists, if not add it
      const newFilter = action.payload;
      const filterIndex = state.data.filters.any.findIndex(
        (filter) => Object.keys(filter)[0] === Object.keys(newFilter)[0]
      );
      if (filterIndex !== -1) {
        state.data.filters.any[filterIndex] = newFilter;
      } else {
        state.data.filters.any.push(newFilter);
      }
    },
    setSort: (state, action) => {
      if (
        action.payload === {} ||
        action.payload === null ||
        action.payload === ""
      ) {
        return;
      }
      state.data.sort = action.payload;
      state.data.page = 1;
    },
    setPage: (state, action) => {
      if (
        action.payload === {} ||
        action.payload === null ||
        action.payload === ""
      ) {
        return;
      }
      state.data.page = action.payload;
    },
    setJWT: (state, action) => {
      const newJWT = action.payload;
      state.data.jwt = newJWT;
    }
  },
});

export const { setData, addDataFilter, setDate, setSort, setJWT, setPage } = dataSlice.actions;

export default dataSlice.reducer;
