import { createSlice } from "@reduxjs/toolkit";

export const selectedImagesSlice = createSlice({
  name: "selectedImages",
  initialState: {
    images: [],
  },
  reducers: {
    addImage: (state, action) => {
      state.images.push(action.payload);
    },
    removeImage: (state, action) => {
      state.images = state.images.filter((image) => image !== action.payload);
    },
    clearImages: (state) => {
      state.images = [];
    },
  },
});

export const { addImage, removeImage, clearImages } =
  selectedImagesSlice.actions;

export default selectedImagesSlice.reducer;
