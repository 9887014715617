import React, { useState, useEffect } from "react";

import "./AlbumsPage.css";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { useSelector } from "react-redux";
import Album from "../../components/Albums/Album/Album";

function AlbumsPage({ url = window.location.href, subCategory = false }) {
  const language = useSelector((state) => state.language.language);
  const direction = useSelector((state) => state.language.direction);
  const translations = JSON.parse(sessionStorage.getItem("translations"));

  const [albums, setAlbums] = useState([]);

  let slug = (url) => new URL(url).pathname.match(/[^\/]+/g).pop();

  const getAllAlbumsApiEndpoint =
    process.env.REACT_APP_BACKEND_API_URL + "/api/getAllAlbums";

  const fetchData = () => {
    try {
      fetch(`${getAllAlbumsApiEndpoint}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => response.json())
        .then((response_data) => {
          setAlbums(response_data);
          console.log("albums", response_data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="home">
      <Header selectedLanguage={language} showSearch={true} showLinks={true} />
      <div style={{ paddingTop: "80px" }}>
        {subCategory ? (
          <Breadcrumb
            items={[
              {
                href: "/",
                title: (
                  <HomeOutlined
                    style={{
                      fontSize: "14px",
                    }}
                  />
                ),
              },
              {
                href: "/gallery",
                title: `${
                  translations.find(
                    (translation) => translation.id === "breadcrumb.gallery"
                  )?.[language]
                }`,
              },
              {
                href: "/albums",
                title: `${
                  translations.find(
                    (translation) => translation.id === "sidebar.albums"
                  )?.[language]
                }`,
              },
              {
                className: "active_breadcrumb",
                title: `${
                  translations.find(
                    (translation) =>
                      translation.id === `albums.${slug(window.location.href)}`
                  )?.[language]
                    ? `${
                        translations.find(
                          (translation) =>
                            translation.id ===
                            `albums.${slug(window.location.href)}`
                        )?.[language]
                      }`
                    : slug(window.location.href)
                }`,
              },
            ]}
            style={{
              margin: "16px",
              marginBottom: "0px",
              fontSize: "18px",
              direction: direction,
            }}
          />
        ) : (
          <Breadcrumb
            items={[
              {
                href: "/",
                title: (
                  <HomeOutlined
                    style={{
                      fontSize: "14px",
                    }}
                  />
                ),
              },
              {
                href: "/gallery",
                title: `${
                  translations.find(
                    (translation) => translation.id === "breadcrumb.gallery"
                  )?.[language]
                }`,
              },
              {
                className: "active_breadcrumb",
                title: `${
                  translations.find(
                    (translation) => translation.id === "sidebar.albums"
                  )?.[language]
                }`,
              },
            ]}
            style={{
              margin: "16px",
              marginBottom: "0px",
              fontSize: "18px",
              direction: direction,
            }}
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <div className="albums-page">
              {albums.map((albums, index) => (
                <Album
                  key={`albums_${albums.uuid}`}
                  title={
                    translations.find(
                      (translation) =>
                        translation.id === `albums.${albums.translation_id}`
                    )?.[language]
                  }
                  id={albums.uuid}
                  album_name={albums.album_name}
                  translationsId={albums.translation_id}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AlbumsPage;
