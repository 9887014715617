import React from "react";

import "./Terms.css";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Terms(props) {
  const navigate = useNavigate();

  const cookiesMinutes = 1; // cookies expiration in minutes

  const handleAccept = () => {
    console.log("accepting terms");
    
    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + cookiesMinutes * 60 * 1000
    ); // 20 minutes from now

    Cookies.set("termsAccepted", "true", { expires: expirationDate });
    props.closeModal();
  };

  const handleDecline = () => {
    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + cookiesMinutes * 60 * 1000
    ); // 20 minutes from now

    Cookies.set("termsAccepted", "false", { expires: expirationDate });
    props.closeModal();

    // redirect to home page
    navigate("/");
  };

  return (
    <>
      <div
        className="terms"
        style={{
          width: "100%",
          overflowY: "auto !important",
        }}
      >
        <div
          style={{
            maxWidth: "75%",
          }}
        >
          <div>
            <h1>תנאי שימוש</h1>
            <h3>
              לקבלת תוצאות החיפוש, אנא אשרו את תנאי השימוש בתחתית העמוד:
              <br />
              <br />
              תנאי שימוש מעודכנים ליום 11.9.14:
              <br />
              <br />
              תנאי שימוש באתר ורישיון לשימוש בתצלומים של המדינה.
            </h3>
            <p>
              אתר אינטרנט זה מנוהל ומתוחזק על ידי משרד ראש הממשלה (להלן -
              המשרד). כל התכנים שבאתר זה, הכוללים בין היתר טקסט, תצלומים,
              תמונות, איורים, מפות, קטעי צליל, קטעי וידיאו, גרפיקה ויישומי תוכנה
              מוגנים בזכות יוצרים, השייכת למשרד או לצדדים שלישיים. כל הזכויות
              ביצירות אלה שמורות לבעלי הזכות. לא ניתן להעתיק יצירות אלה או לעשות
              בהן כל שימוש, אלא לפי תנאי רישיון המפורטים מטה או לפי כל דין
              (למשל, יצירות שזכות היוצרים בהן פקעה לפי הדין).
            </p>
            <br />

            <h3>
              רישיון שימוש בתצלומים
              <br />
              <br />
              תמצית רישיון, מובאת לנוחיות המשתמש וכפופה לתנאי השימוש המלאים:
              <br />
              <br />
              מותר לך:
            </h3>
            <p>
              <ul>
                <li>
                  להשתמש בכל תצלום שמופיע באתר, שלא כתוב עליו " Copyright held
                  by the photographer ©"
                </li>
                <li>
                  להעתיק, להפיץ, להראות ולשלוח כל תצלום כאמור לעיל, אבל לא את
                  כולם ביחד.
                </li>
              </ul>
            </p>

            <h3>אתה חייב:</h3>
            <p>
              <ul>
                <li>לציין ליד התצלום את שם הצלם ואת לשכת העיתונות הממשלתית.</li>
              </ul>
            </p>

            <h3>אסור לך:</h3>
            <p>
              <ul>
                <li>
                  לשנות את התצלום או להשתמש בו בצורה שפוגעת בשמם או בכבודם של
                  המדינה, של לשכת העיתונות הממשלתית או של הצלם.
                </li>
                <li>להשתמש בתצלום לפרסומת מסחרית או לפעילות מפלגתית.</li>
                <li>
                  ליצור יצירות נגזרות או להשתמש בתצלום בצורה אחרת (חוץ ממה שמותר
                  לך, כמו שכתוב למעלה).
                </li>
                <li>להשתמש בתצלום בצורה שמפרה את החוק.</li>
              </ul>
            </p>

            <h3>א. כללי</h3>
            <p>
              <ul>
                <li>
                  המשרד מציע בזאת לציבור רישיון להשתמש בתצלומים שבאתר ואשר זכות
                  היוצרים בהם שייכת למדינה, בהתאם לאמור להלן. יודגש, כי המדינה
                  איננה בעלת זכות היוצרים בכל התצלומים המופיעים באתר, ורישיון זה
                  מוענק אפוא רק לגבי התצלומים שבהם יש למדינה זכות יוצרים.
                </li>
                <li>
                  התצלומים כאמור מוצעים לשימוש בכפוף לתנאי רישיון זה
                  ("הרישיון"). רישיון השימוש ניתן ביחס להעתקי התצלומים, כל אחד
                  בפני עצמו, בלבד. רישיון השימוש אינו ניתן ביחס לחומר מוגן אחר
                  באתר או ביחס לאתר כולו לרבות כלקט, ומובהר כי חל איסור על העתקת
                  אוסף התצלומים בשלמותו.
                </li>
                <li>
                  על ידי מימוש זכות מהזכויות בתצלומים המוענקות על פי רישיון זה,
                  המשתמש (כהגדרתו להלן) מקבל את תנאיו של הרישיון ומסכים להם.
                  נותן הרישיון (כהגדרתו להלן) מעניק למשתמש את הזכויות כמתואר
                  בזאת כנגד הסכמתו של המשתמש לתנאים אלה.
                </li>
              </ul>
            </p>

            <h3>ב. הגדרות</h3>
            <p>
              <ul>
                <li>"נותן הרישיון" הוא משרד ראש הממשלה, מדינת ישראל.</li>
                <li>"המחבר המקורי" הוא האדם או הישות אשר יצרו את היצירה.</li>
                <li>
                  "התצלום" הוא התצלום המוגן בזכות יוצרים המוצע על פי תנאי רישיון
                  זה.
                </li>
                <li>
                  "המשתמש" הוא אדם או ישות המממשים זכויות על פי תנאי רישיון זה
                  ואשר לא הפרו לפנים את תנאי רישיון זה.
                </li>
              </ul>
            </p>

            <h3>ג. הענקת רישיון</h3>
            <p>
              בכפוף לתנאי רישיון זה, נותן הרישיון מעניק למשתמש בזאת רישיון
              כלל-עולמי, ללא תמלוגים, לא בלעדי, בלתי-מוגבל בזמן ולכל מטרה- פרט
              לשימוש במסגרת פרסומת מסחרית מכל סוג, לרבות קידום מוצרים, שירותים
              או דבר-מה אחר, ופרט לשימוש במסגרת פעילות מפלגתית- לעשות בתצלומים
              שזכות היוצרים בהם שייכת לו, את הפעולות הבאות ובתנאים הבאים:
              <ul>
                <li>
                  העתקת התצלום, הפצת עותקים ממנו, העמדתו לרשות הציבור, שידורו,
                  ביצועו בפומבי והענקת רישיון משנה בו, ובלבד שאין בכך משום
                  סילוף, פגימה, שינוי או כל ביצוע פעולה אחרת, לרבות שימוש בהקשר
                  כלשהו, שיש בהם משום הפחתת ערך ביחס ליצירה או העלולים, לדעתו של
                  המחבר המקורי או של נותן הרישיון, לפגוע בכבודו או בשמו של המחבר
                  המקורי, או של נותן הרישיון. סעיף זה ייחשב כמופר עם הודעת המחבר
                  המקורי או נותן הרישיון כי לדעתו מתקיימים אחד או יותר מהמעשים
                  כאמור לעיל, בגין שימוש שעשה המשתמש בתצלום.
                </li>
                <li>
                  בכל שימוש בתצלום, על המשתמש לציין את שם הצלם וכן את לשכת
                  העיתונות הממשלתית.
                </li>
                <li>
                  ניתן לממש את הזכויות המפורטות בפסקה 1 בכל מדיה או פורמט.
                  הזכויות לעיל כוללות את הזכות לבצע שינויים הנדרשים מבחינה טכנית
                  על מנת לממש את הזכויות במדיה או בפורמטים אחרים, אך מלבד זאת לא
                  ניתנת למשתמש הזכות לערוך שינויים בתצלום או ליצור יצירות נגזרות
                  או לבצע כל פעולה ביחס לתצלום שאינן מנויות בסעיף זה, אלא אם
                  קיבל את הסכמת נותן הרישיון מראש ובכתב. בעניין זה, ניתן לפנות
                  למשרד בכתובת המופיעה בהמשך רישיון זה.
                </li>
                <li>
                  למען הסר ספק, זכות השימוש בתצלומים כאמור אינה כוללת תצלומים
                  אשר זכות היוצרים בהם שייכת לגורמים שאינם מדינת ישראל. באתר
                  קיימים תצלומים לגביהם מצוין במפורש: "Copyright held by the
                  photographer ©", אשר השימוש בהם מותנה בקבלת אישורו המוקדם של
                  בעל זכות היוצרים בהם.
                </li>
                <li>
                  יובהר, כי הרישיון אינו מקיף, בהכרח, גם את הזכות המוסרית
                  בתצלום, ויתכן שזו שייכת ליוצר. במקרים שבהם קיים חשש לפגיעה
                  בזכות המוסרית של היוצר, על המשתמש לפנות ליוצר התצלום ולהסדיר
                  את העניין מולו.
                </li>
                <li>
                  עשיית שימוש החורג מגדרו של רישיון זה בתצלום, מותנית בקבלת
                  אישורו המוקדם של נותן הרישיון או של בעל זכות היוצרים, לפי
                  העניין.
                </li>
                <li>
                  אין דבר ברישיון זה כדי לאפשר פעולה אשר עשייתה תהווה עוולה או
                  הפרת הוראת חיקוק כלשהו, לרבות חוק הגנת הפרטיות, התשמ"א-1981
                  (לרבות, בין היתר, פרסום ברבים של תצלום גופת אדם גלויה, באופן
                  שניתן לזהותה, אלא אם כן התקיים אחד התנאים המנויים בסעיף 2א
                  לחוק כאמור).
                </li>
              </ul>
            </p>

            <h3>ד. סיום הרישיון</h3>
            <p>
              רישיון זה והזכויות המוענקות על פיו יסתיימו באופן מיידי עם הפרת
              תנאי הרישיון על ידי המשתמש.
            </p>

            <h3>ה. מצגים, אחריות והסרת אחריות.</h3>
            <p>
              <h4>תכני האתר</h4>
              <ul>
                <li>
                  הרישיון כאמור בסעיף ג', מוצע לציבור "כמות שהוא" ("AsIs").
                  השימוש ברישיון נעשה על אחריות המשתמש בלבד.
                </li>
                <li>
                  מדינת ישראל שומרת לעצמה את הזכות לשנות את תנאי השימוש באתר, את
                  תכני האתר וכן את תנאי הרישיון בכל עת.
                </li>
                <li>
                  מדינת ישראל שומרת לעצמה את הזכות להוריד חומר מהאתר בכל עת.
                </li>
                <li>
                  מדינת ישראל לא תישא באחריות להתאמת הרישיון לצורכי המשתמש.
                </li>
                <li>
                  מדינת ישראל לא תישא באחריות לשינויים שנעשו בחומר המוצג ברישיון
                  על ידי המשתמש או על ידי כל צד ג'.
                </li>
                <li>
                  למדינת ישראל אין כל אחריות כלפי צד ג', הנובעת משימוש ברישיון
                  או שימוש באתר.
                </li>
                <li>
                  מדינת ישראל לא תישא באחריות לכל נזק שנגרם למשתמש או לכל צד ג'
                  כתוצאה ישירה או עקיפה מן השימוש באתר לרבות הורדת תצלומים לפי
                  תנאי הרישיון, נזק שנגרם עקב השימוש ביישומי תוכנה שהורדו ישירות
                  באמצעות השירות או שהופעלו כתוצאה מהשימוש באתר ויישומי אינטרנט
                  (כגוןJava JavaScript, Active-X).
                </li>
              </ul>
            </p>

            <h3>ו. קישורים</h3>
            <p>
              <ul>
                <li>
                  באתר זה נמצאים קישורים (links) לאתרים אחרים. ההוראות שלהלן
                  תחולנה על השימוש בקישורים אלו, ואין בהן כדי לגרוע משאר הוראות
                  תנאי השימוש. הקישורים מיועדים לנוחיות המשתמש בלבד.
                </li>
                <li>
                  יצוין כי ככלל, המדינה איננה אחראית באופן ישיר, באופן עקיף או
                  באופן תורם לקישורים המצויים באתר לאתרים שאינם אתרים של המדינה
                  (להלן - אתרי צד ג'). יובהר, כי אין למדינה כל מידע בדבר החומרים
                  השונים הנמצאים באתרי צד ג' אליהם מפנים הקישורים, או בדבר
                  עדכונם והיא אינה מפקחת על האתרים, לרבות על תוכנם. אין בין
                  המדינה לבין הבעלים של אתרי צד ג' יחסים משפטיים או מסחריים
                  הנוגעים לתוכן האתרים
                </li>
                <li>
                  שלגביהם הוצבו הקישורים באתר זה. אין לפרש קישורים לאתרי צד ג'
                  כמתן גושפנקה, אישור, המלצה או העדפה על ידי המדינה או המשרד
                  לאותם האתרים המקושרים, לרבות למסמכים ולכל חומר אחר המצוי בהם,
                  למפעילי האתרים או למוצרים המוצגים בהם.
                </li>
              </ul>
            </p>

            <h3>ז. שונות</h3>
            <p>
              <ul>
                <li>
                  תנאים נוספים הנוגעים להסכם הרישיון כאמור:
                  <ul>
                    <li>
                      צד לרישיון זה לא יחשב כמי שוויתר על תנאי מתנאי רישיון זה
                      או כמי שהסכים להפרה של אחד מתנאיו, אלא אם הוויתור או
                      ההסכמה נעשו בכתב ואותו צד חתם על כך. הדבר ייעשה בתיאום
                      מראש עם היועץ המשפטי של המשרד.
                    </li>
                    <li>
                      רישיון זה מהווה את מלוא ההסכם בין הצדדים בנוגע ליצירה
                      שבנדון. אין כל הבנות, הסכמים או מצגים אשר לא פורטו כאן.
                      נותן הרישיון לא יהיה כפוף לתנאים נוספים, אשר עשויים להופיע
                      בכל מסר מהמשתמש. לא ניתן לשנות רישיון זה ללא הסכם משותף
                      בכתב בין נותן הרישיון לבין המשתמש כנ"ל.
                    </li>
                  </ul>
                </li>
                <li>
                  שמירת סודיות - מידע אישי שנמסר למשרד באמצעות אתר זה, יישמר
                  בסוד בהתאם להוראות חוק הגנת הפרטיות, התשמ"א - 1981.
                </li>
                <li>
                  פרסומים רשמיים של מדינת ישראל - במידה שתתגלה סתירה או אי-התאמה
                  בין חומר המתפרסם באתר לבין חומר המופיע בפרסומים הרשמיים בכתב
                  של מדינת ישראל, רק החומר בפרסומים הרשמיים כאמור ייחשב כנכון.
                </li>
              </ul>
            </p>

            <h3>ח. פניות למשרד</h3>
            <p>
              בכל שאלה על אודות אתר זה, ושאלות נוספות הנוגעות לתחום פעילות
              המשרד, ניתן לפנות ישירות למשרד בדואר אלקטרוני, בכתובת:{" "}
              <a href="mailto: gpophoto@press.pmo.gov.il">
                gpophoto@press.pmo.gov.il
              </a>
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "40px",
          gap: "10px",
        }}
      >
        <button
          className="terms-button"
          onClick={handleAccept}
          style={{
            backgroundColor: "#4285f4",
            border: "none",
            borderRadius: "5px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "25px",
            paddingRight: "25px",
            cursor: "pointer",
            color: "#ffffff",
            fontWeight: "bold",
            fontFamily: "Rubik",
          }}
        >
          אני מסכים
        </button>
        <button
          className="terms-button"
          onClick={handleDecline}
          style={{
            backgroundColor: "#EE3C5E",
            border: "none",
            borderRadius: "5px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "25px",
            paddingRight: "25px",
            cursor: "pointer",
            color: "#ffffff",
            fontWeight: "bold",
            fontFamily: "Rubik",
          }}
        >
          לא מסכים
        </button>
      </div>
    </>
  );
}

export default Terms;
