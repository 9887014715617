import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import "./InfiniteGallery.css";
import Image from "../Image/Image";

import { setJWT } from "../../features/dataSlice";
import { setImages } from "../../features/imagesSlice";
import { setGlobalNavigationKey } from "../../features/globalsSlice";
import { MdImageSearch } from "react-icons/md";

import { Button, Select } from "antd";

const { Option } = Select;

const InfiniteGallery = () => {
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();
  const images = useSelector((state) => state.images.images);
  const language = useSelector((state) => state.language.language);
  const user = useSelector((state) => state.user);
  const globalNavigationKey = useSelector(
    (state) => state.globals.globals.navigationKey
  );

  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const search = queryParams.get("search");
  const sort = queryParams.get("sort");
  const order = queryParams.get("order");
  // const filters = JSON.parse(queryParams.get("filters"));
  const [filters, setFilters] = useState(
    JSON.parse(queryParams.get("filters"))
  );

  const [navigationKey, setNavigationKey] = useState(0);

  // clean the filters from null values

  const [page, setPage] = useState(1);
  const [updatedGroupedImages, setUpdatedGroupedImages] = useState([]);

  const translations = JSON.parse(sessionStorage.getItem("translations"));
  const config = JSON.parse(sessionStorage.getItem("config"));

  const getDataEndpoint =
    process.env.REACT_APP_BACKEND_API_URL + "/api/getData";

  const nextImages = () => {
    fetchData(page + 1);
    setPage(page + 1);
  };

  // useEffect for handling filters
  useEffect(() => {
    if (filters) {
      queryParams.set("filters", JSON.stringify(filters));
      const updatedQueryString = queryParams.toString();
      window.history.replaceState(null, "", `?${updatedQueryString}`);
    }
  }, [filters]);

  const fetchData = (pageNumber, reset = false) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      if (user.jwt) {
        dispatch(setJWT(user.jwt));
      }

      try {
        fetch(`${getDataEndpoint}?page=${reset ? 0 : pageNumber}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            sort: sort ? sort : "date",
            order: order ? order : "desc",
            search: search ? search : "",
            page: pageNumber,
            // filters: filters ? JSON.parse(filters) : {},
            filters: {
              any: [],
              all: filters,
            },
            jwt: user.jwt,
          }),
        })
          .then((response) => response.json())
          .then((response_data) => {
            if (response_data.length === 0) {
              setHasMore(false);
              setLoading(false);
              resolve(response_data);
              return;
            }

            if (reset) {
              dispatch(setGlobalNavigationKey(0));

              dispatch(setImages(response_data));
              setLoading(false);
            } else {
              for (let i = 0; i < response_data.length; i++) {
                response_data[i].navigationKey = globalNavigationKey + i;

                dispatch(setGlobalNavigationKey(globalNavigationKey + i + 1));
              }

              dispatch(setImages([...images, ...response_data]));
            }

            resolve(response_data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            reject(error);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
        reject(error);
      }
    });
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  useEffect(() => {
    // Group the images again when the images state updates
    const groupedImages = images.reduce((groups, image) => {
      const group = image.group;
      if (!groups[group]) {
        groups[group] = {
          group_name: image.group_name,
          images: [],
        };
      }
      // Only add the image if it doesn't already exist in the group's images
      if (!groups[group].images.find((img) => img.id === image.id)) {
        groups[group].images.push(image);
        // console.log("image id --->", image.id);
      }
      return groups;
    }, {});

    const updatedGroupedImages = Object.values(groupedImages);

    // Update the grouped images state
    setUpdatedGroupedImages(updatedGroupedImages);
  }, [images]);

  // useEffect for handling the sort default value
  useEffect(() => {
    if (!sort) {
      queryParams.set("sort", "date");
      const updatedQueryString = queryParams.toString();
      window.history.replaceState(null, "", `?${updatedQueryString}`);
    }
  }, [sort]);

  return (
    <div
      className="infinite-gallery-container"
      style={{
        paddingBottom: "80px",
      }}
    >
      <div
        className="sort-container"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "10px",
          marginLeft: "10px",
          gap: "10px",
        }}
      >
        <p
          style={{
            padding: "2px",
            fontWeight: "500",
            color: "#ADADAD",
          }}
        >
          {translations
            .find((translation) => translation.id === "gallery.found_results")
            ?.[language].replace("x", images.length.toLocaleString())}
        </p>
        <p
          style={{
            padding: "2px",
            fontWeight: "600",
            color: "#414042",
          }}
        >
          {
            translations.find(
              (translation) => translation.id === "sort.title"
            )?.[language]
          }
        </p>
        <Select
          defaultValue={
            sort
              ? sort
              : translations.find(
                  (translation) => translation.id === "sort.date"
                )?.[language]
          }
          style={{
            width: 120,
            borderRadius: "6px",
            backgroundColor: "white",
            border: "1px solid #d9d9d9",
          }}
          onChange={(value) => {
            queryParams.set("sort", value);
            const updatedQueryString = queryParams.toString();
            window.history.replaceState(null, "", `?${updatedQueryString}`);
            window.location.reload();
          }}
        >
          <Option value="date">
            {
              translations.find(
                (translation) => translation.id === "sort.date"
              )?.[language]
            }
          </Option>
          <Option value="size">
            {
              translations.find(
                (translation) => translation.id === "sort.size"
              )?.[language]
            }
          </Option>
        </Select>
        <Button
          type="primary"
          style={{
            borderRadius: "6px",
            backgroundColor: "white",
            border: "1px solid #d9d9d9",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
          onClick={() => {
            if (order === "asc") {
              queryParams.set("order", "desc");
            } else {
              queryParams.set("order", "asc");
            }
            const updatedQueryString = queryParams.toString();
            window.history.replaceState(null, "", `?${updatedQueryString}`);
            window.location.reload();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#414042"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-arrow-up-down"
          >
            <path d="m21 16-4 4-4-4" />
            <path d="M17 20V4" />
            <path d="m3 8 4-4 4 4" />
            <path d="M7 4v16" />
          </svg>
        </Button>
      </div>
      <div className="infinite-gallery">
        {images.length === 0 && !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 300px)",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <h1
              style={{
                fontWeight: "600",
                color: "#ADADAD",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                userSelect: "none",
              }}
            >
              <MdImageSearch />
              {
                translations.find(
                  (translation) => translation.id === "gallery.no_results"
                )?.[language]
              }
            </h1>
          </div>
        )}
        <InfiniteScroll
          dataLength={images.length}
          next={nextImages}
          hasMore={hasMore}
          scrollThreshold={0.5}
          style={{
            overflow: "hidden",
            marginTop: "-70px",
          }}
        >
          {updatedGroupedImages.map((group) => (
            // console.log(`${`${group.group_name.replace(/\./g, "_")}`}`),
            <div
              key={group.group_name}
              id={`${group.group_name.replace(/\./g, "_")}`}
              className={`${group.group_name.replace(/\./g, "_")}`}
            >
              <h2>
                {
                  translations.find(
                    (translation) => translation.id === `${group.group_name}`
                  )?.[language]
                }
              </h2>
              <div className="image-container">
                {group.images.map((image) => (
                  <Image
                    key={image.id}
                    image={image}
                    id={image.uuid} // Assuming that the server response provides a unique ID as "uuid"
                    navigationKey={image.navigationKey}
                  />
                ))}
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default InfiniteGallery;
