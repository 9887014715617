import React from "react";
import { useNavigate } from "react-router-dom";
import "./Category.css";
import config from "../../../config/config.js";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

function Category({
  title,
  image_url,
  searchTerm,
  id,
  hasSubCategories,
  translationsId,
}) {
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(sessionStorage.getItem("translations"));
  const config = JSON.parse(sessionStorage.getItem("config"));
  const direction = useSelector((state) => state.language.direction);

  const handleCategoryClick = () => {
    if (hasSubCategories) {
      navigate(`/categories/${translationsId}`);
    } else {
      // navigate(`/gallery?search=${searchTerm}`);
      console.log(`categories.${translationsId}`);

      let searchTermTranslated = translations.find(
        (translation) => translation.id === `categories.${translationsId}`
      )?.[language];

      navigate(
        `/gallery?search=${searchTermTranslated}&category=${translationsId}`
      );
    }
    window.location.reload();
  };

  return (
    <div
      className="mobile-category"
      onClick={handleCategoryClick}
      data-aos="fade-up"
      // data-aos-delay={id * 100}
      style={{
        order: id,
      }}
    >
      <div className="mobile-img-gradient">
        <img src={image_url} alt={title} />
        <p className="mobile-category-title">{title}</p>
      </div>
      <div className="mobile-category-overlay"></div>
    </div>
  );
}

export default Category;
