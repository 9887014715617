import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import "./Filters.css";
import { Checkbox, DatePicker, Slider, Tooltip } from "antd";
import moment from "moment";
import config from "../../config/config";
// import dayjs
import dayjs from "dayjs";
import { Select, Space } from "antd";
import { MdFilterListAlt, MdFilterAltOff } from "react-icons/md";

function Filters() {
  const { RangePicker } = DatePicker;

  const queryParams = new URLSearchParams(window.location.search);
  const filtersFromURL = queryParams.get("filters");
  // console.log("filtersFromURL", filtersFromURL);
  const initialFilters = filtersFromURL ? JSON.parse(filtersFromURL) : [];

  const [filters, setFilters] = useState(initialFilters || []);
  const [typeValues, setTypeValues] = useState([]);
  const [orientationValues, setOrientationValues] = useState([]);
  const [colorValues, setColorValues] = useState([]); // New state for color
  const [homerGelemValues, setHomerGelemValues] = useState([]); // New state for homerGelem
  const [isEditedValues, setisEditedValues] = useState([]);
  const [shouldReload, setShouldReload] = useState(false);

  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(sessionStorage.getItem("translations"));
  const config = JSON.parse(sessionStorage.getItem("config"));

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [photographers, setPhotographers] = useState([]);

  const [photographersOptions, setPhotographersOptions] = useState(
    initialFilters.find((filter) => "photographer" in filter)?.photographer ||
      []
  );

  const handlePhotographerChange = (selectedPhotographers) => {
    console.log("handlePhotographerChange", selectedPhotographers);
    updateFilters("photographer", selectedPhotographers);
    setShouldReload(true);
  };

  const handlePhotographerBlur = (value) => {
    // Find all elements with the specified class
    const photographerInputSelectors = document.querySelectorAll(
      ".ant-select-selection-item-content"
    );

    if (photographerInputSelectors.length === 0) {
      return;
    }

    setShouldReload(true);
  };

  const handlePhotographerClear = () => {
    console.log("handlePhotographerClear");

    updateFilters("photographer", []);

    setShouldReload(true);
  };

  const getPhotographersApiEndpoint =
    process.env.REACT_APP_BACKEND_API_URL + "/api/getPhotographers";

  const fetchData = () => {
    fetch(getPhotographersApiEndpoint)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((response_data) => {
        var temp = {};
        temp = response_data.map((item) => ({
          label: item.photographer || "Unknown Photographer",
          value: item.photographer || "unknown",
        }));
        setPhotographers(temp);
        // console.log("Photographers --->", photographers);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    queryParams.set("filters", JSON.stringify(filters));
    updateURL(queryParams.toString());

    if (shouldReload) {
      // Retrieve date range from URL when component mounts
      const dateFilter = filters.find((filter) => "photoDate" in filter);
      console.log("dateFilter", dateFilter);
      if (dateFilter && dateFilter.photoDate) {
        const { from, to } = dateFilter.photoDate;
        console.log(from, to);
        setFromDate(dayjs.unix(from));
        setToDate(dayjs.unix(to));
      }

      window.location.reload();
      setShouldReload(false);

      console.log("dateFilter", dateFilter);
    }
  }, [shouldReload, fromDate, toDate]);

  useEffect(() => {
    queryParams.set("filters", JSON.stringify(filters));
    updateURL(queryParams.toString());

    if (shouldReload) {
      const photographersFilter = filters.find(
        (filter) => "photographer" in filter
      );

      window.location.reload();
      setShouldReload(false);

      console.log("photographersFilter", photographersFilter);
    }
  }, [shouldReload, photographersOptions]);

  useEffect(() => {
    const typeFilter = filters.find((filter) => "type" in filter);
    if (typeFilter) {
      setTypeValues(typeFilter.type || []);
    } else {
      setTypeValues([]);
    }

    const orientationFilter = filters.find((filter) => "orientation" in filter);
    if (orientationFilter) {
      setOrientationValues(orientationFilter.orientation || []);
    } else {
      setOrientationValues([]);
    }

    const colorFilter = filters.find((filter) => "color" in filter); // Update color state
    if (colorFilter) {
      setColorValues(colorFilter.color || []);
    } else {
      setColorValues([]);
    }

    const homerGelemFilter = filters.find((filter) => "isEdited" in filter); // Update homerGelem state
    if (homerGelemFilter) {
      setHomerGelemValues(homerGelemFilter.homerGelem || []);
    } else {
      setHomerGelemValues([]);
    }

    const isEditedFilter = filters.find((filter) => "isEdited" in filter); // Update homerGelem state
    if (isEditedFilter) {
      setisEditedValues(isEditedFilter.isEdited || []);
    } else {
      setisEditedValues([]);
    }

    const photographerFilter = filters.find(
      (filter) => "photographer" in filter
    );
    if (photographerFilter) {
      setPhotographersOptions(photographerFilter.photographer || []);
    } else {
      setPhotographersOptions([]);
    }
  }, [filters]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const filtersFromURL = queryParams.get("filters");
    const initialFilters = filtersFromURL ? JSON.parse(filtersFromURL) : [];

    // Initialize state values from URL parameters
    const dateFilter = initialFilters.find((filter) => "photoDate" in filter);
    if (dateFilter && dateFilter.photoDate) {
      const { from, to } = dateFilter.photoDate;
      setFromDate(from ? dayjs.unix(from) : null);
      setToDate(to ? dayjs.unix(to) : null);
    }

    // set options from URL parameters
    const photographerFilter = initialFilters.find(
      (filter) => "photographer" in filter
    );
    // console.log("photographerFilter", photographerFilter);

    setFilters(initialFilters);
  }, []);

  const handleTypeChange = (checkedValues) => {
    updateFilters("type", checkedValues.length > 0 ? checkedValues : null);
    setShouldReload(true);
  };

  const handleOrientationChange = (checkedValues) => {
    updateFilters(
      "orientation",
      checkedValues.length > 0 ? checkedValues : null
    );
    setShouldReload(true);
  };

  const handleColorChange = (checkedValues) => {
    updateFilters("color", checkedValues.length > 0 ? checkedValues : null);
    setShouldReload(true);
  };

  const handleisEditedChange = (checkedValues) => {
    updateFilters("isEdited", checkedValues.length > 0 ? checkedValues : null);
    setShouldReload(true);
  };

  const handleFromPhotoDateChange = (date, dateString) => {
    setFromDate(date);
    setToDate(null);
    updateFilters("photoDate", getUpdatedDateObject([date, null]));
    setShouldReload(true);
  };

  const handleToPhotoDateChange = (date, dateString) => {
    // Make the date range inclusive by setting it to the end of the day
    date = date ? date.endOf("day") : null;
    setToDate(date);
    updateFilters("photoDate", getUpdatedDateObject([fromDate, date]));
    setShouldReload(true);
  };

  // const handleFromPhotoDateClear = () => {
  //   setFromDate(null);
  //   updateFilters("photoDate", getUpdatedDateObject([null, null]), true);
  //   setShouldReload(true);
  // };

  // const handleToPhotoDateClear = () => {
  //   setToDate(null);
  //   updateFilters("photoDate", getUpdatedDateObject([fromDate, null]), true);
  //   setShouldReload(true);
  // };

  const getUpdatedDateObject = (dates) => {
    if (dates && (dates[0] || dates[1])) {
      const updatedDates = dates.map((date) => (date ? date.clone() : null));

      console.log(updatedDates);

      return {
        from: updatedDates[0] ? updatedDates[0].unix() : null,
        to: updatedDates[1] ? updatedDates[1].unix() : null,
      };
    }
    return null;
  };

  const updateFilters = (key, value) => {
    const existingFilterIndex = filters.findIndex((filter) => key in filter);

    if (key === "photoDate") {
      const updatedFilters = [...filters];
      if (existingFilterIndex !== -1) {
        if (value) {
          // Update the "photoDate" filter
          updatedFilters[existingFilterIndex] = { [key]: value };
        } else {
          // Remove the "photoDate" filter
          updatedFilters.splice(existingFilterIndex, 1);
        }
        setFilters(updatedFilters);
      } else {
        // Add the "photoDate" filter if value is truthy
        if (value) {
          updatedFilters.push({ [key]: value });
          setFilters(updatedFilters);
        }
      }
      return;
    }

    if (existingFilterIndex !== -1) {
      if (value) {
        // Update the filter if value is truthy
        const updatedFilters = [...filters];
        updatedFilters[existingFilterIndex] = { [key]: value };
        setFilters(updatedFilters);
      } else {
        // Remove the filter if value is falsy
        const updatedFilters = filters.filter(
          (_, index) => index !== existingFilterIndex
        );
        setFilters(updatedFilters);
      }
    } else {
      // Add the filter if value is truthy
      if (value) {
        setFilters([...filters, { [key]: value }]);
      }
    }
  };

  const updateURL = (queryString) => {
    const newURL = `${window.location.pathname}?${queryString}`;
    window.history.pushState({}, "", newURL);
    // save to browser history
  };

  const disabledDate = (current) => {
    // If the date is after today, disable it
    return current && current > moment().endOf("day");
  };

  return (
    <div className="filters">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>
          {
            translations.find(
              (translation) => translation.id === "filters.title"
            )?.[language]
          }
        </h2>
        <Tooltip
          title={
            translations.find(
              (translation) => translation.id === "filters.clear"
            )?.[language]
          }
          placement="bottom"
        >
          <div
            onClick={() => {
              if (filters.length === 0) {
                return;
              }
              setFilters([]);
              setShouldReload(true);
            }}
            style={{
              cursor: "pointer",
              fontSize: "20px",
            }}
          >
            {filters.length > 0 ? <MdFilterAltOff /> : <MdFilterListAlt />}
          </div>
        </Tooltip>
      </div>
      {config.shouldDisplayTypeFilter && (
        <div className="filter-container">
          <p
            style={{
              fontWeight: "600",
              color: `${config.textColor}`,
            }}
          >
            {
              translations.find(
                (translation) => translation.id === "filters.file_type"
              )?.[language]
            }
          </p>
          <div>
            <Checkbox.Group value={typeValues} onChange={handleTypeChange}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Checkbox
                value="image"
                style={{
                  color: `${config.textColor}`,
                }}
              >
                {
                  translations.find(
                    (translation) => translation.id === "filters.images"
                  )?.[language]
                }
              </Checkbox>
              <Checkbox
                value="video"
                style={{
                  color: `${config.textColor}`,
                }}
              >
                {
                  translations.find(
                    (translation) => translation.id === "filters.videos"
                  )?.[language]
                }
              </Checkbox>
            </Checkbox.Group>
          </div>
        </div>
      )}

      <div className="filter-container">
        <p
          style={{
            fontWeight: "600",
            color: `${config.textColor}`,
          }}
        >
          {
            translations.find(
              (translation) => translation.id === "filters.photo_date"
            )?.[language]
          }
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <DatePicker
            className="from"
            size={"middle"}
            // format={moment.localeData().longDateFormat("L")}
            format={"DD/MM/YYYY"}
            placeholder={
              translations.find(
                (translation) => translation.id === "filters.from"
              )?.[language]
            }
            onChange={handleFromPhotoDateChange}
            value={fromDate}
            // value={dayjs.unix(fromDate)}
            disabledDate={disabledDate}
          />
          <DatePicker
            className="to"
            size={"middle"}
            // format={moment.localeData().longDateFormat("L")}
            format={"DD/MM/YYYY"}
            placeholder={
              translations.find(
                (translation) => translation.id === "filters.to"
              )?.[language]
            }
            style={{ marginTop: "10px" }}
            disabled={fromDate === null}
            onChange={handleToPhotoDateChange}
            value={toDate}
            // value={dayjs.unix(toDate)}
            // value={moment.unix(236127386)}
            disabledDate={disabledDate}
          />
        </div>
      </div>

      {config.shouldDisplayOrientationFilter && (
        <div className="filter-container">
          <p
            style={{
              fontWeight: "600",
              color: `${config.textColor}`,
            }}
          >
            {
              translations.find(
                (translation) => translation.id === "filters.aspect_ratio"
              )?.[language]
            }
          </p>
          <div>
            <Checkbox.Group
              value={orientationValues}
              onChange={handleOrientationChange}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            
            >
              <Checkbox
                value="portrait"
                style={{
                  color: `${config.textColor}`,
                }}
              >
                {
                  translations.find(
                    (translation) => translation.id === "filters.height"
                  )?.[language]
                }
              </Checkbox>
              <Checkbox
                value="landscape"
                style={{
                  color: `${config.textColor}`,
                }}
              >
                {
                  translations.find(
                    (translation) => translation.id === "filters.width"
                  )?.[language]
                }
              </Checkbox>
              <Checkbox
                value="square"
                style={{
                  color: `${config.textColor}`,
                }}
              >
                {
                  translations.find(
                    (translation) => translation.id === "filters.square"
                  )?.[language]
                }
              </Checkbox>
            </Checkbox.Group>
          </div>
        </div>
      )}

      {config.shouldDisplayColorFilter && (
        <div className="filter-container">
          <p
            style={{
              fontWeight: "600",
              color: `${config.textColor}`,
            }}
          >
            {
              translations.find(
                (translation) => translation.id === "filters.color_type"
              )?.[language]
            }
          </p>
          <div>
            <Checkbox.Group value={colorValues} onChange={handleColorChange}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Checkbox
                value="colourful"
                style={{
                  color: `${config.textColor}`,
                }}
              >
                {
                  translations.find(
                    (translation) => translation.id === "filters.colourful"
                  )?.[language]
                }
              </Checkbox>
              <Checkbox
                value="blackAndWhite"
                style={{
                  color: `${config.textColor}`,
                }}
              >
                {
                  translations.find(
                    (translation) =>
                      translation.id === "filters.black_and_white"
                  )?.[language]
                }
              </Checkbox>
            </Checkbox.Group>
          </div>
        </div>
      )}

      {config.shouldDisplayPhotographerFilter && (
        <div className="filter-container">
          <p
            style={{
              fontWeight: "600",
              color: `${config.textColor}`,
            }}
          >
            {
              translations.find(
                (translation) => translation.id === "filters.photographer"
              )?.[language]
            }
          </p>
          {/* Select ANTD */}
          {/* <Space
            style={{
              width: "100%",
              maxWidth: "260px",
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
            }}
            direction="vertical"
            onClear={() => {
              handlePhotographerClear();
            }}
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder={
                translations.find(
                  (translation) => translation.id === "filters.photographer"
                )?.[language]
              }
              onChange={handlePhotographerChange}
              onBlur={handlePhotographerBlur}
              options={photographers}
              defaultValue={photographersOptions}
              onClear={handlePhotographerClear}
            />
          </Space> */}

          {/* Checkbox group with max-height */}
          <div
            style={{
              maxHeight: "145px",
              overflowY: "auto",
            }}
          >
            <Checkbox.Group
              value={photographersOptions}
              onChange={handlePhotographerChange}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            
            >
              {photographers.map((photographer) => (
                <Checkbox
                  value={photographer.value}
                  style={{
                    color: `${config.textColor}`,
                  }}
                >
                  {photographer.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </div>
        </div>
      )}

      {config.shouldDisplayIsEditedFilter && (
        <div className="filter-container">
          <p
            style={{
              fontWeight: "600",
              color: `${config.textColor}`,
            }}
          >
            {
              translations.find(
                (translation) => translation.id === "filters.homer_type"
              )?.[language]
            }
          </p>
          <div>
            <Checkbox.Group
              value={isEditedValues}
              onChange={handleisEditedChange}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            
            >
              <Checkbox
                value={0}
                style={{
                  color: `${config.textColor}`,
                }}
              >
                {
                  translations.find(
                    (translation) => translation.id === "filters.homer_gelem"
                  )?.[language]
                }
              </Checkbox>
              <Checkbox
                value={1}
                style={{
                  color: `${config.textColor}`,
                }}
              >
                {
                  translations.find(
                    (translation) =>
                      translation.id === "filters.edited"
                  )?.[language]
                }
              </Checkbox>
              <Checkbox
                value={2}
                style={{
                  color: `${config.textColor}`,
                }}
              >
                {
                  translations.find(
                    (translation) =>
                      translation.id === "filters.not_homer_gelem"
                  )?.[language]
                }
              </Checkbox>
            </Checkbox.Group>
          </div>
        </div>
      )}
    </div>
  );
}

export default Filters;
