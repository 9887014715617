import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./HeroMobile.css";
import CategoriesMobile from "../CategoriesMobile/CategoriesMobile";

function HeroMobile() {
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const searchTerm = queryParams.get("search");

  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(sessionStorage.getItem("translations"));
  const config = JSON.parse(sessionStorage.getItem("config"));
  const direction = useSelector((state) => state.language.direction);

  let heroBackgroundImage = config.heroBackgroundImage;

  if (!heroBackgroundImage || heroBackgroundImage === "") {
    heroBackgroundImage =
      "https://storage.googleapis.com/kal-media-placeholders/placeholder-image.webp";
  }

  let logoNameInConfig = "";

  logoNameInConfig = "logo-" + language.toString();

  let logoImage = config[logoNameInConfig];

  if (!logoImage || logoImage === "") {
    logoImage =
      "https://storage.googleapis.com/kal-media-placeholders/placeholder-image.webp";
  }

  let israelLogoImage = config["logo-israel"];

  return (
    <div>
      <div
        className="mobile-popup"
        style={{
          direction: `${direction}`,
          color: `${config.secondaryColor}`,
        }}
      >
        <div className="mobile-popup-content">
          <h3>
            {
              translations.find(
                (translation) => translation.id === "hero-mobile.title"
              )?.[language]
            }
          </h3>
          <p>
            {
              translations.find(
                (translation) => translation.id === "hero-mobile.description"
              )?.[language]
            }
          </p>
          {/* <a
            style={{
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            מעבר לתצוגה של מסך נייח
          </a> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-monitor-smartphone"
          >
            <path d="M18 8V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8" />
            <path d="M10 19v-3.96 3.15" />
            <path d="M7 19h5" />
            <rect width="6" height="10" x="16" y="12" rx="2" />
          </svg>
        </div>
      </div>
      <div
        className="mobile-nav"
        style={{
          backgroundImage: `linear-gradient(to right, ${config.headerColorStart}, ${config.headerColorEnd})`,
        }}
      >
        <div
          className="logo"
          style={{
            padding: "0px 10px",
            margin: "0px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {config.shouldDisplayIsraelLogo && <img src={israelLogoImage} />}
          <img src={logoImage} />
        </div>
      </div>
      <div className="mobile-unclickable-container"></div>
      <div
        className="mobile-hero"
        style={{
          backgroundImage: `url(${heroBackgroundImage})`,
          color: `${config.white}`,
        }}
      >
        <h1
          className="mobile-title-hero"
          style={{
            color: `${config.secondaryColor}`,
          }}
        >
          {
            translations.find(
              (translation) => translation.id === "home.title"
            )?.[language]
          }
        </h1>
        <p
          className="mobile-description-hero"
          style={{
            color: `${config.secondaryColor}`,
          }}
        >
          {
            translations.find(
              (translation) => translation.id === "home.description"
            )?.[language]
          }
        </p>
        <CategoriesMobile />
      </div>
    </div>
  );
}

export default HeroMobile;
