import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import imagesReducer from "../features/imagesSlice";
import imageReducer from "../features/imageSlice";
import selectedImagesReducer from "../features/selectedImagesSlice";
import dataReducer from "../features/dataSlice";
import userReducer from "../features/userSlice";
import languageReducer from "../features/languageSlice";
import globalsReducer from "../features/globalsSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "language"],
};

const rootReducer = combineReducers({
  images: imagesReducer,
  image: imageReducer,
  selectedImages: selectedImagesReducer,
  data: dataReducer,
  user: userReducer,
  language: languageReducer,
  globals: globalsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
