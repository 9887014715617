import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./GenericPage.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import config from "../../config/config";

function GenericPage({ title, description }) {
  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(sessionStorage.getItem("translations"));
  const config = JSON.parse(sessionStorage.getItem("config"));
  const direction = useSelector((state) => state.language.direction);

  return (
    <div style={{ direction: direction }}>
      <Header showLinks={true} />
      <div
        className="generic-page"
        style={{
          maxWidth: "1000px",
          margin: "auto",
          paddingTop: "80px",
          paddingBottom: "120px",
        }}
      >
        <h1>{title}</h1>

        <div className="custom-p">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default GenericPage;
