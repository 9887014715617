import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InfiniteGallery from "../../components/InfiniteGallery/InfiniteGallery";

import "./Home.css";

import { Modal } from "antd";

import Hero from "../../components/Hero/Hero";
import Terms from "../../components/Terms/Terms";
import Categories from "../../components/Categories/Categories";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init(
  {
    duration: 1000,
  },
  []
);

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(sessionStorage.getItem("translations"));
  const config = JSON.parse(sessionStorage.getItem("config"));

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const showModal = urlParams.get("showModal");

    if (showModal === "true") {
      setIsModalOpen(true);
    }
  }, []);

  const showModal = () => {
    var body = document.getElementsByTagName("html")[0];
    body.style.overflow = "hidden";
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    var body = document.getElementsByTagName("html")[0];
    body.style.overflow = "auto";
    setIsModalOpen(false);
  };

  const [timeline, setTimeline] = useState([]);

  const getTimelineSectionApiEndpoint =
    process.env.REACT_APP_BACKEND_API_URL + "/api/getTimelineSection";

  const fetchData = () => {
    try {
      fetch(`${getTimelineSectionApiEndpoint}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => response.json())
        .then((response_data) => {
          setTimeline(response_data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    console.log("timeline", timeline);
  }, []);

  return (
    <div>
      <Header showSearch={false} showLinks={true} />
      <div style={{ paddingTop: "60px" }}>
        <div>
          <Hero />

          <Categories />

          {/* <InfiniteGallery /> */}
        </div>

        {config.shouldDisplayTimeline == "true" && (
          <div
            className="timeline-container"
            data-aos="fade-up"
            style={{
              paddingBottom: "150px",
              display: "flex",
              flexDirection: "column",
              gap: "50px",
            }}
          >
            <div
              className="timeline-container"
              style={{
                paddingBottom: "150px",
                display: "flex",
                flexDirection: "column",
                gap: "50px",
              }}
            >
              <div className="timeline-header">
                {
                  translations.find(
                    (translation) => translation.id === "timeline.title"
                  )?.[language]
                }
              </div>

              {timeline.map((item, index) => (
                <div
                  className={`timeline-item ${
                    index % 2 === 0 ? "right" : "left"
                  }`}
                  key={item.id}
                >
                  <div
                    className="timeline-img"
                    data-aos={index % 2 === 0 ? "fade-left" : "fade-right"}
                  >
                    <img
                      src={item.img}
                      style={{ height: "400px" }}
                      alt={`Timeline ${index + 1}`}
                    />
                  </div>
                  <div
                    className="timeline-text"
                    data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
                  >
                    <div className="timeline-text-header">{item.title}</div>
                    <div className="timeline-text-body">{item.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[]}
          centered
          width={"80%"}
          style={{
            margin: "5%",
            fontFamily: "Rubik",
          }}
          className="upload-modal"
        >
          <Terms closeModal={handleCancel} />
        </Modal>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
