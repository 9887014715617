import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Categories.css";

import Category from "./Category/Category";

function Categories() {
  const [categories, setCategories] = useState([]);

  const language = useSelector((state) => state.language.language);
  const direction = useSelector((state) => state.language.direction);
  const translations = JSON.parse(sessionStorage.getItem("translations"));
  const config = JSON.parse(sessionStorage.getItem("config"));

  const getHomeCategoriesApiEndpoint =
    process.env.REACT_APP_BACKEND_API_URL + "/api/getHomeCategories";

  const fetchData = () => {
    try {
      fetch(`${getHomeCategoriesApiEndpoint}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => response.json())
        .then((response_data) => {
          if (direction === "ltr") {
            response_data.reverse();

            response_data.forEach((category, index) => {
              category.id = index;
            });
          }
          setCategories(response_data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
      data-aos="fade-up"
    >
      <h2>
        {
          translations.find(
            (translation) => translation.id === "home.chosen_categories"
          )?.[language]
        }
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <div className={`categories ${direction}`}>
          {categories.map((category, index) => (
            <Category
              key={`category_${category.id}`}
              title={
                translations &&
                translations.find(
                  (translation) =>
                    translation.id === `categories.${category.translationId}`
                )?.[language]
              }
              image_url={category.imgURL}
              searchTerm={category.searchTerm}
              id={category.id}
              hasSubCategories={category.hasSubCategories ? true : false}
              translationsId={
                category.translationId ? category.translationId : ""
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Categories;
