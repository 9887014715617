import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Searchbar.css";

import { AutoComplete } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import config from "../../config/config";

function Searchbar() {
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const searchTerm = queryParams.get("search");

  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(sessionStorage.getItem("translations"));
  const config = JSON.parse(sessionStorage.getItem("config"));
  const direction = useSelector((state) => state.language.direction);

  let searching = false;

  async function getSearchCompletion(searchedText) {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_API_URL + `/api/getSearchTerms`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchedText: searchedText,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const newOptions = data.map((word) => ({
          label: word,
          value: word,
        }));

        // Check if searchedText is already in the options
        const searchedTextOption = newOptions.find(
          (option) => option.value === searchedText
        );

        // If not found, add it to the options
        if (!searchedTextOption) {
          newOptions.push({
            label: searchedText,
            value: searchedText,
          });
        }

        setOptions(newOptions);
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      setOptions([]);
      console.error(`Error fetching data: ${error}`);
    }
  }

  const updateQueryParams = () => {
    // Get the updated query string
    const updatedQueryString = queryParams.toString();

    // Replace the current URL without triggering a full page reload
    window.history.replaceState(null, "", `gallery?${updatedQueryString}`);

    window.location.reload();
  };

  const onSelect = (data) => {
    searching = true;

    setValue(data);

    postSearchTerm(data);

    queryParams.set("search", data);

    updateQueryParams();

    setTimeout(() => {
      searching = false;
    }, 1000);
  };

  const onKeyDown = (event) => {
    if (searching) {
      return;
    } else {
      // check if autocomplete is open
      if (event.keyCode === 13 && value.length <= 0) {
        onSelect("");
      } else if (event.keyCode === 13 && options.length >= 0) {
        onSelect(value);
      }
    }
  };

  const onFocus = async (data) => {
    if (searchTerm) {
      setValue(searchTerm);
      await getSearchCompletion(searchTerm);
    }

    if (value.length > 0) {
      await getSearchCompletion(value);
    }

    // user select all text in input
    const input = document.querySelector(".ant-select-selection-search-input");
    input.select();
    
  };

  const postSearchTermEndpoint =
    process.env.REACT_APP_BACKEND_API_URL + "/api/postSearchTerm";

  const postSearchTerm = (searchTerm) => {
    if (searchTerm.length > 0) {
      try {
        fetch(postSearchTermEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            search: searchTerm,
          }),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const onChange = (data) => {
    setValue(data);
  };

  return (
    <div
      className="search-bar-hero"
      style={{
        backgroundColor: `${config.white}`,
      }}
    >
      <AutoComplete
        defaultValue={searchTerm}
        options={options}
        style={{ width: "100%", direction: direction }}
        onSelect={onSelect}
        onSearch={(value) => {
          if (value.trim() === "") {
            setOptions([]);
          } else {
            getSearchCompletion(value.trim());
          }
        }}
        onChange={onChange}
        placeholder={
          translations.find(
            (translation) => translation.id === "header.search_placeholder"
          )?.[language]
        }
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      />
      <div
        className="search-button"
        onClick={() => {
          if (value.trim() === "") {
            onSelect("");
          } else {
            onSelect(value.trim());
          }
        }}
        style={{
          backgroundColor: `${config.primaryColor}`,
          color: `${config.white}`,
        }}
      >
        <SearchIcon />
      </div>
    </div>
  );
}

export default Searchbar;
