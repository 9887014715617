import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "language",
  initialState: { language: "hebrew", direction: "rtl" , name: "עברית"},
  reducers: {
    setLanguage: (state, action) => {
      if (action.payload[0] !== undefined || action.payload[1] !== undefined) {
        state.language = action.payload[0];
        state.direction = action.payload[1];
        state.name = action.payload[2];
      }
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
