import React, { useState, useEffect } from "react";

import "./AlbumPage.css";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setImage } from "../../features/imageSlice";
import { setImages } from "../../features/imagesSlice";
import InfiniteGallery from "../../components/InfiniteGallery/InfiniteGallery";

function AlbumPage() {
  const language = useSelector((state) => state.language.language);
  const direction = useSelector((state) => state.language.direction);
  const translations = JSON.parse(sessionStorage.getItem("translations"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [albumImages, setAlbumImages] = useState([]);
  const [currentImage, setCurrentImage] = useState({});

  const [uuid, setUuid] = useState(null);

  let slug = (url) => new URL(url).pathname.match(/[^\/]+/g).pop();

  const getAlbumImagesApiEndpoint =
    process.env.REACT_APP_BACKEND_API_URL + "/api/getAlbumImages";

  const fetchDataAlbumImages = () => {
    try {
      fetch(`${getAlbumImagesApiEndpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          album_name: slug(window.location.href),
          user_id: "snirego@kaleidoo.ai",
        }),
      })
        .then((response) => response.json())
        .then((response_data) => {
          setAlbumImages(response_data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataAlbumData = () => {
    const getAlbumDataApiEndpoint =
      process.env.REACT_APP_BACKEND_API_URL + "/api/getAlbumData";

    fetch(`${getAlbumDataApiEndpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        album_name: slug(window.location.href),
        user_id: "snirego@kaleidoo.ai",
      }),
    })
      .then((response) => response.json())
      .then((response_data) => {
        setCurrentImage(response_data);
        dispatch(setImages(response_data));
      }
      )
      .catch((error) => {
        console.error("Error fetching data:", error);
      }
      );
  };


  useEffect(() => {
    setUuid(slug(window.location.href));
    fetchDataAlbumImages();
    fetchDataAlbumData();
  }, []);

  function fetchImage(uuid) {
    const getImageEndpoint =
      process.env.REACT_APP_BACKEND_API_URL + "/api/getImage";

    fetch(getImageEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        dispatch(setImage(data[0]));
        console.log("Image data fetched from the backend API:", data);
      })
      .catch((error) => {
        console.error(
          "An error occurred while fetching image data from the backend API:",
          error
        );
      });
  }

  function handleImageClick(image_uuid) {
    let image_to_show = fetchImage(image_uuid);

    dispatch(setImage(image_to_show));
    if (slug(window.location.href) !== image_uuid) {
      navigate(image_uuid);
    }
  }

  return (
    <div className="home">
      <Header selectedLanguage={language} showSearch={true} showLinks={true} />
      <div style={{ paddingTop: "80px" }}>
        <Breadcrumb
          items={[
            {
              href: "/",
              title: (
                <HomeOutlined
                  style={{
                    fontSize: "14px",
                  }}
                />
              ),
            },
            {
              href: "/gallery",
              title: `${
                translations.find(
                  (translation) => translation.id === "breadcrumb.gallery"
                )?.[language]
              }`,
            },
            {
              href: "/albums",
              title: `${
                translations.find(
                  (translation) => translation.id === "sidebar.albums"
                )?.[language]
              }`,
            },
            {
              className: "active_breadcrumb",
              title: `${
                translations.find(
                  (translation) =>
                    translation.id === "albums." + slug(window.location.href)
                )?.[language]
                  ? translations.find(
                      (translation) =>
                        translation.id ===
                        "albums." + slug(window.location.href)
                    )?.[language]
                  : slug(window.location.href)
              }`,
            },
          ]}
          style={{
            margin: "16px",
            marginBottom: "0px",
            fontSize: "18px",
            direction: direction,
          }}
        />

        {/* <InfiniteGallery albumImages={albumImages} /> */}

        <div className="albums-page">
          {albumImages.map((albumImages, index) => (
            <div
              className="album-gallery-image"
              onClick={() => {
                handleImageClick(slug(albumImages.image));
              }}
              key={index}
            >
              <img src={albumImages.image} />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AlbumPage;
